// Copyright © 2020 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import Wizard from './wizard'
import Steps from './steps'
import Step from './step'
import Stepper from './stepper'
import Form from './form'
import { useWizardContext, WizardContext } from './context'

Wizard.Steps = Steps
Wizard.Steps.displayName = 'Wizard.Steps'
Wizard.Step = Step
Wizard.Step.displayName = 'Wizard.Step'
Wizard.Stepper = Stepper
Wizard.Stepper.displayName = 'Wizard.Stepper'
Wizard.Form = Form
Wizard.Form.displayName = 'Wizard.Form'

export { Wizard as default, useWizardContext, WizardContext }
